import React from "react";
import { Row, Col, Button, Input, Table } from "reactstrap";
import { TextLabel, TextGroup, CustomSelect } from "../../Styled";
import classNames from "classnames";
import { PreviewHead, FixHead } from "./HomeStyled";
import {
  postImportRecords,
  getCustomers,
  getPartsRevs,
  getPartsRevsByCustomerId,
} from "../../../actions";
import { connect } from "react-redux";
import { BOM_PARSE } from "../../../constants/urls";
import { toaster } from "../../../function";
import { ToastsStore } from "react-toasts";
import Dropdown from "./../../Dropdown";
import { CloseIcon } from "../../../constants/icons";

const ColumnOptions = ({
  columns,
  filterColumns,
  columnIndex,
  selectedColumn,
}) => {
  let checkValue = selectedColumn.find((item) => {
    return item.columnIndex === columnIndex;
  });

  let classActive = classNames(
    { "bg-green": checkValue },
    {
      ignored: checkValue
        ? checkValue.value === "ignore"
          ? true
          : false
        : false,
    }
  );

  return (
    <Input
      type="select"
      onChange={(e) => filterColumns(e.target.value, columnIndex)}
      className={classNames("p-table", classActive)}
    >
      {columns.map((item, index) => {
        if (checkValue !== undefined && checkValue.value === item.code_name) {
          return (
            <option
              key={index}
              selected
              value={item.code_name}
              disabled={item.disabled}
            >
              {item.name}
            </option>
          );
        }
        return (
          <option key={index} value={item.code_name} disabled={item.disabled}>
            {item.name}
          </option>
        );
      })}
    </Input>
  );
};

class ImportPreview extends React.Component {
  constructor(props) {
    super(props);
    this.customerRef = React.createRef();
    this.partRevRef = React.createRef();

    this.state = {
      columns: props.columns,
      selectedColumn: [],
      customer: "",
      part: "",
      start_line: "",
      importBtnEnable: true,
      bomData: [...props.data],
      fileName: [...props.fileName],
      isOpen: false,
      isPartREVOpen: false,
      partRevs: [],
      toggleModal: props.toggleModal,
      toggleHistoryModal: props.toggleHistoryModal,
    };
  }

  componentDidMount() {
    let selectedColumn = this.state.selectedColumn;

    Object.values(this.props.data[0]).map((item, index) => {
      let checkKey;
      this.props.columnAliases.map((columnAlias) => {
        if (columnAlias.list_of_aliases.includes(item)) {
          checkKey = columnAlias;
        }
        return null;
      });
      if (checkKey !== undefined) {
        this.filterColumns(checkKey.code_name, index + 1);
        return selectedColumn.push({
          columnIndex: index + 1,
          name: this.props.columns.find(
            (column) => column.code_name === checkKey.code_name
          ).name,
          value: checkKey.code_name,
        });
      }
      return selectedColumn.push({ columnIndex: index + 1, value: "ignore" });
    });
    this.props.getCustomers(this.props.user.token);
    this.props.getPartsRevs(this.props.user.token);

    this.setState({ selectedColumn });
  }

  componentWillUnmount() {
    this.props.columns.map((item) => {
      item.disabled = false;
      return item;
    });
    this.setState({
      columns: this.props.columns,
      selectedColumn: [],
      name: "",
      part: "",
      importBtnEnable: true,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.newCustomer !== prevProps.newCustomer) {
      this.setState({ customer: this.props.newCustomer });
    }
    if (this.props.newPartRev !== prevProps.newPartRev) {
      this.setState({ part: this.props.newPartRev });
      this.handleChangePartREV(null, this.props.newPartRev);
    }
  }

  shouldComponentUpdate(prev, next) {
    if (prev.customer !== next.customer) {
      return true;
    }
    return false;
  }

  filterColumns = (e, columnIndex) => {
    let columns = this.state.columns;
    let selectedColumn = this.state.selectedColumn;
    var needEnable = "";
    let columnsFilters = columns.find((item) => item.code_name === e);

    selectedColumn.map((item) => {
      if (item.columnIndex === columnIndex) {
        needEnable = item.value;
        item.value = e;
        item.name = columnsFilters.name;
      }
      return item;
    });

    columns.map((item) => {
      if (item.code_name === e && e !== "ignore") {
        item.disabled = true;
      }

      if (item.code_name === needEnable) {
        item.disabled = false;
      }

      return item;
    });
    this.setState({ columns });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

    this.arraySplice(e);
  };

  handleClickOutside = (key) => {
    this.setState({
      [key]: false,
    });
  };

  handleChangeCustomer = (e, selected) => {
    this.props.getPartsRevsByCustomerId(this.props.user.token, selected.id);
    localStorage.setItem("selectedCustomer", JSON.stringify(selected));
    this.setState({
      customer: selected,
      isOpen: false,
      part: "",
    });
  };

  handleChangePartREV = (e, selected) => {
    this.setState({
      part: selected,
      isPartREVOpen: false,
      selectedPartRevId: selected.id,
    });
  };

  arraySplice = (e) => {
    if (parseInt(e.target.value) === 0 || (e.target.value && parseInt(e.target.value) > this.props.data.length)) {
      this.setState({ bomData: this.props.data });
      toaster(ToastsStore, "error", "Please fill valid start line.");
      return;
    }

    let data = [...this.props.data].slice(
      parseInt(e.target.value) - 1,
      this.props.data.length
    );
    this.setState({ bomData: data });
  };

  filterToSelectedColumn = async (data, selectedDataValue) => {
    return await data.map((item) => {
      return Object.keys(item).forEach((col) => {
        if (!selectedDataValue.includes(col)) {
          delete item[col];
        }
        return item;
      });
    });
  };

  validateDesignatorAndQuantity = (removeIgnoreColumns) => {
    let invalidQuantityColumns = [];
    removeIgnoreColumns.forEach((row, index) => {
      if (row.designator != null) {
        let designator = String(row.designator);
        designator = designator.replace(/\s/g, "");
        designator = designator.split(",");

        let designatorCount = 0;
        let singleDesignatorRegExp = new RegExp("^@?[a-zA-Z]+\\d+$");
        let rangeDesignatorRegExp = new RegExp(
          "^@?[a-zA-Z]+\\d+[-]@?[a-zA-Z]*\\d+$"
        );
        var numberPatternRegExp = /\d+/g;

        designator.forEach((d) => {
          if (singleDesignatorRegExp.test(d)) {
            designatorCount++;
          } else if (rangeDesignatorRegExp.test(d)) {
            let rangeNumbers = d.match(numberPatternRegExp);
            let rangeCount = rangeNumbers[1] - rangeNumbers[0] + 1;
            if (rangeCount > 0) {
              designatorCount += rangeCount;
            }
          }
        });

        if (row.quantity !== designatorCount) {
          invalidQuantityColumns.push(index + 1);
        }
      }
    });

    if (invalidQuantityColumns.length > 0) {
      alert(
        "Quantity is either NaN or does not match with the Reference Designator for BOM lines (shown in maroon): " +
          invalidQuantityColumns.join(", ") +
          "." +
          "\n\nWe will still import the file and use qauntity as the source of truth, please rectify and import again if you wish."
      );
    }

    return invalidQuantityColumns;
  };

  importData = async () => {
    let columnsData = this.state.bomData;
    let changeColumnIndex = [];
    let ignoreColumnsIndex = [];
    let dataColumns = Object.keys(columnsData[0]);
    let requireFields = ["manufacturer_part_number", "quantity"];
    let fieldCount = 0;
    // fields validation
    if (!this.state.customer || !this.state.part) {
      return toaster(
        ToastsStore,
        "error",
        "Please select customer name and part #REV."
      );
    }

    this.state.selectedColumn.map((item) => {
      if (requireFields.includes(item.value)) {
        fieldCount++;
      }

      if (item.value === "ignore") {
        return ignoreColumnsIndex.push(dataColumns[item.columnIndex - 1]); // get ignore column name
      } else {
        return changeColumnIndex.push(item.columnIndex); // set changed column index
      }
    });

    if (fieldCount !== requireFields.length) {
      return toaster(
        ToastsStore,
        "error",
        "Part# and Quantity must be selected."
      );
    }

    let changeColumnRows = this.state.selectedColumn.filter((item) => {
      return item.value !== "ignore";
    });
    let removeIgnoreColumns = columnsData.map((item) => {
      Object.keys(item).forEach((col, index) => {
        let change_column_name = changeColumnRows.find(
          (x) => x.columnIndex === index + 1
        );
        if (change_column_name) {
          item[change_column_name.value] = item[col];
          if (change_column_name.value !== col) {
            delete item[col];
          }
        }
      });
      ignoreColumnsIndex.forEach((e) => delete item[e]);

      return item;
    });

    let invalidQuantityColumns = this.validateDesignatorAndQuantity(
      removeIgnoreColumns
    );

    let selectedDataValue = this.state.selectedColumn.map(({ value }) => value);
    await this.filterToSelectedColumn(removeIgnoreColumns, selectedDataValue);

    let importRecord = {
      bomlines: removeIgnoreColumns,
      invalidQuantityColumns: invalidQuantityColumns,
      date: new Date(),
      name: this.state.customer.name,
      part: this.state.part.name,
      selectedColumns: changeColumnRows,
    };
    const formData = new FormData();
    formData.append("file", this.props.file[0], this.props.file[0].name);
    formData.append("bomlines", JSON.stringify(removeIgnoreColumns));
    formData.append("part_rev_id", this.state.selectedPartRevId);
    const storeBOMParse = async () => {
      this.setState({ importBtnEnable: false });
      this.props.toggleModal();
      this.props.toggleHistoryModal();
      try {
        const res = await fetch(BOM_PARSE, {
          method: "POST",
          headers: {
            authorization: "Bearer " + this.props.user.token,
          },
          body: formData,
        });

        const data = await res.json();

        if (data.code >= 200 && data.code < 400) {
          importRecord.bomId = data.data.bom_id;
          this.props.postImportRecords(this.props.user.token, importRecord);

          localStorage.setItem(
            "importColumn",
            Object.keys(removeIgnoreColumns[0]).length
          );
          this.props.showBomLines(true);
          this.setState({
            selectedColumn: [],
            customer: "",
            part: "",
          });
          toaster(ToastsStore, "success", "Product imported successfully.");
        } else {
          if (data.message) alert(data.message);
        }
        this.setState({ importBtnEnable: true });
      } catch (e) {
        console.log(e);
        this.setState({ importBtnEnable: true });
      }
    };

    storeBOMParse();
  };

  render() {
    let ingnorColumns = this.state.selectedColumn.map((e) => {
      return e.value === "ignore" ? e.columnIndex : false;
    });

    return (
      <React.Fragment>
        <PreviewHead>
          <Row className="align-items-end">
            <span
              className="importCloseIcon"
              onClick={() => this.state.toggleModal()}
            >
              <CloseIcon />
            </span>
          </Row>
          <Row className="align-items-end">
            <Col lg="4">
              <div className="successAlert">
                <h5>
                  <img src="/images/CheckCircle.png" alt="CheckCircle" />
                  XLSX BOM File loaded Successfully.
                </h5>
                <span>(Total Rows : {this.state.bomData.length})</span>
              </div>
              <div className="fileInfo">
                <h5>
                  <img src="/images/Shape-fill.png" alt="excel_icon" />
                  <div>
                    <span>BOM Name</span>
                    <span>{this.state.fileName}</span>
                  </div>
                </h5>
              </div>
            </Col>
            <Col md="8" lg="6" xl="5" className="offset-xl-1">
              <Row>
                <Col>
                  <TextGroup>
                    <TextLabel className="d-block">
                      Customer Name
                      <span
                        className="addNewBtn"
                        onClick={this.props.addNewForm}
                      >
                        Add New
                      </span>
                    </TextLabel>
                    <div className="clearfix"></div>
                    <CustomSelect>
                      <div
                        className="form-control p-form"
                        onClick={() =>
                          this.setState({ isOpen: !this.state.isOpen })
                        }
                      >
                        {this.state.customer !== ""
                          ? this.state.customer.name
                          : "Select Customer"}
                      </div>
                      <Dropdown
                        data={this.props.customers}
                        isOpen={this.state.isOpen}
                        type="isOpen"
                        ref={this.customerRef}
                        placeholder="Select customer"
                        click={this.handleClickOutside}
                        handleChangeEvent={this.handleChangeCustomer}
                        name={this.state.customer}
                      />
                    </CustomSelect>
                  </TextGroup>
                </Col>
                <Col>
                  {this.state.customer && (
                    <TextGroup>
                      <TextLabel className="d-block">
                        Part #REV
                        {this.state.customer !== "" && (
                          <span
                            className="addNewBtn"
                            onClick={this.props.addNewPartRevsForm}
                          >
                            Add New
                          </span>
                        )}
                      </TextLabel>
                      <div className="clearfix"></div>
                      <CustomSelect>
                        <div
                          className="form-control p-form"
                          onClick={() =>
                            this.setState({
                              isPartREVOpen: !this.state.isPartREVOpen,
                            })
                          }
                        >
                          {this.state.part !== ""
                            ? this.state.part.name
                            : "Select PartRevs"}
                        </div>
                        <Dropdown
                          data={this.props.partRevs}
                          isOpen={this.state.isPartREVOpen}
                          type="isPartREVOpen"
                          ref={this.partRevRef}
                          placeholder="Select part"
                          click={this.handleClickOutside}
                          handleChangeEvent={this.handleChangePartREV}
                          name={this.state.part}
                        />
                      </CustomSelect>
                    </TextGroup>
                  )}
                </Col>
              </Row>
            </Col>
            <Col md="4" lg="2">
              <TextGroup>
                <div className="xl-flex">
                  <TextLabel>Start Line</TextLabel>
                  <Input
                    value={this.state.start_line || ""}
                    type="text"
                    placeholder="Enter Start Line"
                    name="start_line"
                    onChange={(e) => this.handleChange(e)}
                  />
                </div>
              </TextGroup>
              <TextGroup>
                <Button
                  className="btn-block importBtn blue p-form"
                  onClick={this.importData}
                  disabled={!this.state.importBtnEnable}
                >
                  Import BOM
                </Button>
              </TextGroup>
            </Col>
          </Row>
        </PreviewHead>
        <div className="table-responsive mh-430">
          <Table className="table-bordered hovered">
            <thead>
              <FixHead>
                {Object.keys(this.state.bomData[0]).map((column, index) => {
                  return (
                    <th className="p-0" key={index}>
                      <CustomSelect>
                        <ColumnOptions
                          columnIndex={index + 1}
                          columns={this.state.columns}
                          filterColumns={this.filterColumns}
                          selectedColumn={this.state.selectedColumn}
                        />
                      </CustomSelect>
                    </th>
                  );
                })}
              </FixHead>
              <tr>
                {Object.keys(this.state.bomData[0]).map((column, index) => {
                  return (
                    <th
                      key={index}
                      className={classNames({
                        ignored: ingnorColumns.includes(index + 1),
                      })}
                    >
                      {column}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {this.state.bomData.map((rows, index) => {
                return (
                  <tr key={index}>
                    {Object.keys(rows).map((column, index) => {
                      return (
                        <td
                          key={index}
                          className={classNames({
                            ignored: ingnorColumns.includes(index + 1),
                          })}
                        >
                          {rows[column]}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  customers: state.customers,
  partRevs: state.partRevs,
});
export default connect(mapStateToProps, {
  postImportRecords,
  getCustomers,
  getPartsRevs,
  getPartsRevsByCustomerId,
})(ImportPreview);
