import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { login } from "../../actions";
import classNames from "classnames";

import styled from "styled-components";
import {
  Container,
  TextField,
  TextLabel,
  TextGroup,
  Button,
  InputGroup,
  InputGroupAppend,
  InputGroupText,
} from "../Styled";
import { ToastsStore } from "react-toasts";
import { Alert } from "reactstrap";

import { formValidation, toaster } from "../../function.js";
import ExpireSession from "../Modals/ExpireSession";

const LoginPage = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  background-color: #f4f7fc;
  font-family: "Poppins", sans-serif;
`;
const LoginBox = styled.div`
  width: 406px;
  max-width: calc(100% - 40px);
  margin: 20px auto;
  padding: 52px 26px 60px;
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(28, 41, 90, 0.08);
  background-color: #ffffff;
  @media screen and (max-width: 550px) {
    padding: 25px;
  }
`;
const LoginTitle = styled.h2`
  color: #242f57;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 21px;
`;
const ForgotLink = styled.a`
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  padding-top: 19px;
  color: #0091ff;
  display: block;
  &:hover {
    text-decoration: none;
    color: #000;
  }
`;
const CopyRight = styled.p`
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  color: #636e95;
  margin-top: 42px;
`;
const requiredField = {
  email: ["required", "email"],
  password: ["required"],
};

const LoginForm = ({ onSubmit, onChange, errors }) => {
  const eyeRef = useRef();
  const [showPassword, setShowPassword] = useState("password");
  const handlePassword = () => {
    let password = showPassword === "password" ? "text" : "password";
    eyeRef.current.src =
      showPassword === "password" ? "/images/eye-off.png" : "/images/eye.png";
    setShowPassword(password);
  };

  return (
    <form onSubmit={onSubmit} onChange={onChange}>
      {errors.commonError ? (
        <Alert color="danger" className="error-text">
          {errors.commonError}
        </Alert>
      ) : null}
      <TextGroup
        className={classNames({ error: errors.email || errors.commonError })}
      >
        <TextLabel>Email Address</TextLabel>
        <TextField type="text" name="email" placeholder="Enter email" />
        {errors.email ? (
          <span className="error-text">{errors.email}</span>
        ) : null}
      </TextGroup>
      <TextGroup
        className={classNames({ error: errors.password || errors.commonError })}
      >
        <TextLabel>Password</TextLabel>
        <InputGroup>
          <TextField
            type={showPassword}
            name="password"
            placeholder="Enter passwrod"
          />
          <InputGroupAppend>
            <InputGroupText onClick={handlePassword}>
              <img
                ref={eyeRef}
                src="/images/eye.png"
                alt="eye-off"
                height="18"
              />
            </InputGroupText>
          </InputGroupAppend>
        </InputGroup>
        {errors.password ? (
          <span className="error-text">{errors.password}</span>
        ) : null}
      </TextGroup>
      <Button type="submit">Login</Button>
      <ForgotLink href="/forgotpassword">Forgot Password</ForgotLink>
    </form>
  );
};

const Login = ({ user, login }) => {
  const [formError, setFormError] = useState({});
  const [expireModal, setExpireModal] = useState(false);

  useEffect(() => {
    setExpireModal(localStorage.getItem("expired") === "true" ? true : false);
  }, []);

  localStorage.removeItem("collapsed");
  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      email: { value: email },
      password: { value: password },
    } = e.target;

    const validate = formValidation(requiredField, { email, password });
    setFormError(validate.errors);

    if (validate.formIsValid) {
      const data = await login({ email, password });
      if (data.code === 401) {
        setFormError({
          commonError: "Incorrect username or password.",
        });
      }
      if (data.code === 403) {
        setFormError({
          commonError: "Sorry, your account is disabled. Please contact support team for re-activation.",
        });
      }
      if (data.data.is_allowed_ip === false) {
        setFormError({
          commonError: "Sorry, you are not allowed to access the system from this IP.",
        });
      }
      sessionStorage.setItem("remember_me", false);
    } else {
      toaster(ToastsStore, "error", "There were some errors.");
    }
  };

  const handleChange = async (e) => {
    setFormError({});
  };

  const closeExpired = () => {
    localStorage.removeItem("expired");
    setExpireModal(false);
  };

  return (
    <React.Fragment>
      <ExpireSession show={expireModal} close={closeExpired} />
      <LoginPage>
        <Container>
          <LoginBox>
            <LoginTitle>
              <img src="/images/Logo.png" alt="Logo" />
            </LoginTitle>
            <LoginForm
              onSubmit={handleSubmit}
              onChange={handleChange}
              errors={formError}
            />
          </LoginBox>
          <CopyRight>Powered by Trylene. © 2020 All rights reserved</CopyRight>
        </Container>
        {user.token && <Redirect to="/" />}
      </LoginPage>
    </React.Fragment>
  );
};

Login.propTypes = {
  user: PropTypes.shape({}).isRequired,
  login: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({ user: state.user });
export default connect(mapStateToProps, { login })(Login);
