import ExcelJS from "exceljs";
import * as FileSaver from "file-saver";
import { storeResultantBom } from "../../../actions";

const addATemplateRowWithBasicCellFormat = (sheet, titleData) => {
  let columnsCount = Object.keys(titleData).length;
  let rowCellsStrings = Array(columnsCount).join(".").split(".");
  let rowTemplate = sheet.addRow(rowCellsStrings);
  rowTemplate._cells.forEach((cell, index) => {
    cell.border = {
      top: { style: "thin" },
      bottom: { style: "thin" },
      left: { style: "thin" },
      right: { style: "thin" },
    };
  });
  return rowTemplate;
};

const addSheetTitleRowAndGetInputColumnsCount = (
  rowTemplate,
  titleData,
  columnNames
) => {
  let inputColumnsCount = 0;
  let titleRow = Object.keys(titleData);
  rowTemplate._cells.forEach((cell, index) => {
    let argb = "6c757d";
    let cellValue = columnNames.find(
      (item) => item.code_name === titleRow[index]
    );

    if (!cellValue) {
      cellValue = { name: titleRow[index] };
      argb = titleRow[index].indexOf("Quote ") !== -1 ? "0080ff" : "04b78a";
    } else {
      inputColumnsCount++;
    }

    cell.value = cellValue.name;
    cell.font = {
      color: { argb: "FFFFFFFF" },
      bold: true,
    };
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: argb },
    };
  });

  return inputColumnsCount;
};

const isQuantityCellWithInvalidValue = (
  cellColumnTitle,
  invalidQuantityColumns,
  index
) => {
  return (
    cellColumnTitle === "quantity" && invalidQuantityColumns.includes(index + 1)
  );
};

const getRichDescriptionCellValue = (descriptionObject) => {
  let descriptionCellRichTextArray = [];

  // eslint-disable-next-line array-callback-return
  descriptionObject.description.map(function (t) {
    if (t.key) {
      descriptionCellRichTextArray.push({
        font: {
          color: { argb: "636e95" },
        },
        text: t.key + ": ",
      });

      let value = t.link ? t.link : t.value ? t.value : "n/a";
      descriptionCellRichTextArray.push({
        text: value + " \r\n",
      });
    }
  });
  return {
    richText: descriptionCellRichTextArray,
  };
};

const getRichResultCellValue = (resultObject) => {
  let resultCellRichTextArray = [];

  if (resultObject.title) {
    resultCellRichTextArray.push({
      font: {
        color: { argb: "4b499f" },
      },
      text: resultObject.title + " \r\n",
    });
  }

  
  if(resultObject?.text?.length > 0){
    // eslint-disable-next-line array-callback-return
    resultObject.text.map(function (t) {
      if (t.text) {
        resultCellRichTextArray.push({
          font: {
            italic: t.class === "tdItalicSubTitle",
          },
          text: t.text,
        });
      }
    });
  }
  return {
    richText: resultCellRichTextArray,
  };
};

const getColorForResultCell = (resultObject) => {
  switch (resultObject.class) {
    case "bg-green":
      return "effbf8";
    case "bg-yellow":
      return "ffedad";
    case "bg-light-maroon":
      return "fdeeee";
    case "bg-gray-opacity":
      return "e9e9fc";
    default:
      return "ffffff";
  }
};

const addSheetDataRow = (
  rowTemplate,
  titleData,
  item,
  invalidQuantityColumns,
  rowIndex
) => {
  let titleRow = Object.keys(titleData);
  rowTemplate._cells.forEach((cell, index) => {
    let cellValue = item[titleRow[index]];
    let argb = isQuantityCellWithInvalidValue(
      titleRow[index],
      invalidQuantityColumns,
      rowIndex
    )
      ? "fdeeee"
      : "ffffff";

    if (
      cellValue != null &&
      typeof cellValue === "object" &&
      cellValue.description != null
    ) {
      cellValue = getRichDescriptionCellValue(cellValue);
    } else if (cellValue != null && typeof cellValue === "object") {
      argb = getColorForResultCell(cellValue);
      cellValue = getRichResultCellValue(cellValue);
    }

    cell.value = cellValue;
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: {
        argb: argb,
      },
    };
    cell.alignment = { wrapText: true };
  });
};

const ExportExcel = async (
  user,
  data,
  invalidQuantityColumns,
  columns,
  exportLocally
) => {
  const bomlines = data.bomlines;
  const bomId = data.bomId;

  const workbook = new ExcelJS.Workbook();

  var sheet = workbook.addWorksheet("result");
  let rowTemplate = addATemplateRowWithBasicCellFormat(sheet, bomlines[0]);
  let inputColumnsCount = addSheetTitleRowAndGetInputColumnsCount(
    rowTemplate,
    bomlines[0],
    columns
  );

  bomlines.forEach((item, rowIndex) => {
    let rowTemplate = addATemplateRowWithBasicCellFormat(sheet, bomlines[0]);
    addSheetDataRow(
      rowTemplate,
      bomlines[0],
      item,
      invalidQuantityColumns,
      rowIndex
    );
  });

  // Try the solution from the following link if required: https://github.com/exceljs/exceljs/issues/83
  // Currently, allocating more width to the result details column.
  for (let i = inputColumnsCount + 2; i <= sheet.columnCount; i++) {
    if ((i - inputColumnsCount - 1) % 3 === 2) {
      sheet.getColumn(i).width = 70;
    } else {
      sheet.getColumn(i).width = 12;
    }
  }
  sheet.properties.defaultColWidth = 20;

  let fileNameFromLocalStorage = localStorage.getItem("fileName");
  let fileName =
    "Result-" + (fileNameFromLocalStorage ? fileNameFromLocalStorage : "BOM");
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  await workbook.xlsx.writeBuffer().then(async (data) => {
    const blob = new Blob([data], { type: fileType });
    blob.lastModifiedDate = new Date();
    blob.name = fileName;

    // call backend to store the bom
    await storeResultantBom(user.token, {
      bomId: bomId,
      fileName: fileName,
      file: blob,
    });

    if (exportLocally) {
      FileSaver.saveAs(blob, fileName);
    }
  });
};

export default ExportExcel;
